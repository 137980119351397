/* Libraries */
import React, {useEffect} from 'react';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';

/* App Store: mobile/repsonsive */
import {useMobileStoreActions, useMobileSidebarOpenMobile} from '@store/client/store.mobile';
import { useResponsiveIsMobileOrTablet } from '@store/client/store.responsive';

/* Components */
const DarkOverlayDynamic = dynamic(() => import('./DarkOverlay'), {ssr: false});
const NavDynamic = dynamic(() => import('./Nav'), {ssr: false});

/* Types */
type SidebarProps = $FixMe;

const Sidebar = (props: SidebarProps) => {
  const isMobileOrTablet = useResponsiveIsMobileOrTablet();

  /* hooks */
  const router = useRouter();

  /* App Store: Mobile */
  const sidebarOpenMobile = useMobileSidebarOpenMobile();
  const {updateToggleSidebar} = useMobileStoreActions();

  useEffect(() => {
    if (sidebarOpenMobile) {
      updateToggleSidebar();
    }
  }, [router.asPath]);

  useEffect(() => {
    if (!isMobileOrTablet) {
      updateToggleSidebar(false);
    }
  }, [isMobileOrTablet]);

  return (
    <>
      {isMobileOrTablet && sidebarOpenMobile && (
        <>
          <DarkOverlayDynamic {...props} isOpen={sidebarOpenMobile} />
          <NavDynamic {...props} isOpen={sidebarOpenMobile} />
        </>
      )}
    </>
  );
};

export default React.memo(Sidebar);
