import {useQueryClient} from '@tanstack/react-query';
import {brandingQueryKeys} from '@integrations/partners/branding/queries.keys';

import {PartnerBaseAttributes} from '@integrations/partners/partners.types';

/*
 * Read operation
 *
 * This returns the "cache" user
 * No api happens here, just a helper to read cache
 *
 * Async/Await not needed here.
 * */
const useBrandingCache = (): PartnerBaseAttributes => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(brandingQueryKeys.all) as PartnerBaseAttributes;
};

export default useBrandingCache;
