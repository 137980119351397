import {Children} from 'types/base.types';

type EitherProps = {
  condition: boolean;
  children: Children;
};

/**
 * Taken from the monad naming vibe. Either is a conditional that either returns
 * what you stipulate or default, given a conditional.
 * @param {EitherProps} props
 * @returns {React.ReactElement}
 * @constructor
 */
const Either = (props: EitherProps): React.ReactElement => {
  const {condition, children} = props;

  return <>{condition ? children![0] : children![1] ?? null}</>;
};

export default Either;
