import React from 'react';
import {Icon} from 'ht-styleguide';
import styles from './phoneicon.module.scss';

type PhoneIconProps = {
  className?: string;
};

/**
 * ChatOrPhoneIcon
 *
 * Only to be used in headers.
 */
const PhoneIcon = ({className = ''}: PhoneIconProps) => <Icon name="phone-outlined" className={`${styles.phoneIcon} ${className}`} />;

export default PhoneIcon;
