/* Utils */
import {GetCartItemCount} from '@utils/cart';
/* Styles */
import styles from './cartcount.module.scss';

type CartCountProps = {
  themeStyles?: string;
};

const CartCount = ({themeStyles = ''}: CartCountProps) => {
  const itemCount = GetCartItemCount();

  return itemCount > 0 ? (
    <div data-testid="cart-preview-count" className={`${styles.topNavItemCount} ${themeStyles}`}>
      {itemCount}
    </div>
  ) : null;
};

export default CartCount;
