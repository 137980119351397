/* Libraries */
import cn from 'classnames';
import {Icon} from 'ht-styleguide';

/* Components */
import CartCount from '@components/PageLayouts/Header/Parts/CartPreviewIcon/CartCount';
/* Utils | Constants */
import {cartPath} from '@constants/paths';
/* Styles */
import styles from '@components/PageLayouts/Header/Standard/standardheader.module.scss';
import React from 'react';

/* Types inline */
type CartPreviewProps = Partial<{
  containerStyles: string;
  iconName: string;
  themeStyles: string;
  searchClick: BaseAnyFunction;
  onClose: BaseAnyFunction;
  onClick?: BaseAnyFunction | null;
}>;

const CartPreviewIcon = ({onClick, containerStyles, iconName = 'cart-outlined', themeStyles}: CartPreviewProps) => {
  /* Styles */
  const appliedContainerStyles = cn(styles.topNavCart, containerStyles, themeStyles);

  /* TODO: REVISIT: update once app is integrated next/link */
  const goToCart = () => {
    window.location.href = cartPath();
  };

  return (
    <div data-component="CartPreviewIcon" className={appliedContainerStyles} onClick={onClick || goToCart}>
      <Icon name={iconName} />
      <CartCount themeStyles={themeStyles} />
    </div>
  );
};

export default CartPreviewIcon;

/*
 * This allows for any header to get the usage of the cart icon and badge number
 *
 * @example
 * Usage:
 *  <CartPreviewIcon
 *      cart={cart}
 *      containerStyles={containerStyles}
 *      themeStyles={themeStyles}
 *  />
 *
 * @remarks
 * Some assumptions can be made, so please see proptypes for required
 *
 * */
