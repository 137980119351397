import {getValueByKey} from '@utils/object';
import PartnerHeader from '@components/PageLayouts/Header/Partner/Default';

interface Partner {
  partner: any;
  PartnerHeader: typeof PartnerHeader | null;
}

const useGetPartner = (partnerObj: Partner['partner']): Partner => {
  if (!partnerObj) return {partner: {}, PartnerHeader: null};

  const landingTemplate = getValueByKey(partnerObj, 'landingTemplateName');
  const Header = partnerObj?.id ? PartnerHeader : null;

  return {
    partner: {...partnerObj, landingTemplate},
    PartnerHeader: Header,
  };
};

export default useGetPartner;
