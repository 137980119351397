import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';
import {simpleStoreSetter} from '@store/client/store.utils';
/* ----------------------------------------------------------------------- */
/* --------------------- TYPES  ------------------------------------------ */
/* ----------------------------------------------------------------------- */
export enum NoticeTypes {
  SNACKBAR_NOTICE = 'snackbarNotice',
  MODAL_NOTICE = 'modalNotice',
}

export type Notification = Partial<{
  title: string;
  error_title: string;
  errors: string | string[];
  page: string;
  id: number;
  content: string;
  type: string;
  dismissableOnClick: boolean;
  autoClose: boolean;
}>;

export type Notifications = {
  notifications: Notification[];
};

export type NotificationState = {
  notifications: Notification[];
};

/* ----------------------------------------------------------------------- */
/* --------------------- DEFINE INITIAL STATE ---------------------------- */
/* ----------------------------------------------------------------------- */
const INITIAL_STATE = {
  notifications: [],
};

/* ----------------------------------------------------------------------- */
/* ---------------------  NOTIFICATIONS STORE SETUP  --------------------- */
/* ----------------------------------------------------------------------- */
const useNotificationStore = createWithEqualityFn<NotificationState>()(immer((set, get) => INITIAL_STATE));
export default useNotificationStore;

/* ----------------------------------------------------------------------- */
/* ------------ NOTIFICATIONS LEVEL ACTIONS ------------------------------ */
/* ----------------------------------------------------------------------- */
export const updateAppNotification = (notification: Notification) =>
  useNotificationStore.setState(state => {
    const {notifications} = state;
    const {error_title: title = 'Sorry', page = '', id = notifications ? notifications.length + 1 : 1, errors = ''} = notification;

    state.notifications = ([] as Notification[]).concat({
      title,
      content: ([] as string[]).concat(errors).join('<br />'),
      page,
      id,
    });
  });

export const deleteAppNotification = simpleStoreSetter(useNotificationStore, 'notifications', []);
