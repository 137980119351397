import React from 'react';
import {Icon} from 'ht-styleguide';
import {noop} from '@utils/event';
import styles from './chatorphoneicon.module.scss';

type PhoneIconProps = {
  className?: string;
  onHandleChatPhoneClick: () => void;
};

/**
 * ChatOrPhoneIcon
 *
 * Only to be used in headers.
 */
const ChatOrPhoneIcon = ({className = '', onHandleChatPhoneClick = noop}: PhoneIconProps) => (
  <Icon name="chat" onClick={onHandleChatPhoneClick} className={`${styles.phoneChatIcon} ${className}`} />
);

export default ChatOrPhoneIcon;
