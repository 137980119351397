import React from 'react';
import cn from 'classnames';
import {LoaderComponent} from 'ht-styleguide';
import {InView as ReactInView, IntersectionObserverProps} from 'react-intersection-observer';
import Either from '@components/Either';

import {Children} from 'types/base.types';

type LazyComponentProps = {
  options?: IntersectionObserverProps;
  children: Children;
  minHeight: string;
  className?: string;
  loader?: boolean | undefined;
  /* Number between 0 and 1 indicating the percentage that should be visible before triggering. Can also be an array of numbers, to create multiple trigger points. */
  threshold?: number;
};
const LazyComponent = ({options, children, minHeight = '100px', className, loader = true, threshold = 0}: LazyComponentProps) => (
  <ReactInView {...options} threshold={threshold} triggerOnce>
    {({inView, ref}) => (
      <>
        <div ref={ref} className={cn(className, 'placeholderHeight')}>
          <Either condition={inView}>
            <>{children}</>
            <div>{loader && <LoaderComponent />}</div>
          </Either>
        </div>
        <style jsx>{`
          .placeholderHeight {
            min-height: ${minHeight};
          }
        `}</style>
      </>
    )}
  </ReactInView>
);

export default LazyComponent;
