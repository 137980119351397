/* cookies & utils */
// import { getPromotionalBannerCookie } from '@utils/cookies/promoBanner';

/**
 * These are the pages that the promo will not show on.
 * @type {*[]}
 */
// const EXCLUDED_BY_COOKIES = [getPromotionalBannerCookie, /* () => Boolean(getBrandingCookie()) */];

/**
 * Break out the cookie tests.
 * @param conditionalArray
 * @returns {boolean}
 */

// const hasExclusionByCookie = () => EXCLUDED_BY_COOKIES.some(cookie => cookie() === true);
/**
 * Flow control to determine if any of our conditions to omit/exclude pass
 */

const excludeByCondition = (props: any) => ([
  () => !props.promo,
  // hasExclusionByCookie,
].some(fn => fn()));
/**
 * This extracts out the determination of whether to show the promo or not as far as external
 * determinants. ie.. pagename. I asked if there would be more than one promo, and
 * they said no, so, no need to match a promo type with conditionals.
 *
 * We will use both pathname and pagename to determine to show.
 *
 * @param Component
 * @returns {Function}
 * @constructor
 *
 */
// eslint-disable-next-line react/display-name
const promoGuard = (props: any) => excludeByCondition(props);


export default promoGuard;
