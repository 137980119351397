// @ts-nocheck
/* eslint-disable consistent-return */
import { useRef, useEffect } from 'react';

const globalWindow = process.browser ? window : {};

function useEventListener({ eventName, handler, element = globalWindow }) {
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener, { passive: true });
    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element]);
}

export default useEventListener;

// https://usehooks.com/useEventListener/
