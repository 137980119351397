/*  Price Types */
export const BREAKDOWN_PRICE_TYPES = {
  hourly: 'hourly',
  fixed: 'fixed',
};

export const SERVICE_ADJUSTMENT_TYPES = {
  /*
    Service Adjustments are adjustments attached to an individual service.

    Service Adjustments do not come through with a type property like the cart, only with a name
    and amount. So instead of filtering by type we need to filter by substring.
  */
  SHP_DISCOUNT: 'subscription discount',
  SUBSIDIZED: 'covered by', // Complete message from server is "Covered by ${partnerName}"
  AUTOMATIC_DISCOUNT: 'automatic discount', // See discount_skus table for applicable skus
};

export const ITEM_REMOVAL_TOAST_MESSAGES = {
  SERVICE: 'Service removed',
  MEMBERSHIP: 'Membership removed',
  DEFAULT: 'Removed from cart.',
} as const;

export const BOOKING_STAGES = {
  VERIFICATION: 'verification', // exists on FE only
  ADDRESS: 'address',
  AVAILABILITY: 'availability',
  PAYMENT: 'payment',
  SUMMARY: 'summary',
} as const;
