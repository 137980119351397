import React, {memo} from 'react';
import cn from 'classnames';
import Link from 'next/link';

/* Query Selectors */
import {useUserSubscriptionPartnerSelector} from '@store/queries/user/useUser.selector';
import usePartnerCache from '@integrations/partners/usePartnerQuery.cache';

/* Components */
import HTLogo from '@components/PageLayouts/Header/Parts/HTLogo';
import PartnerLogo from '@components/PageLayouts/Header/Parts/PartnerLogo';

/* Utils */
import {useGetPartnerImage} from '@integrations/partners/partners.utils';

/* Types */
import {PartnerBaseAttributes, PartnerClient} from '@integrations/partners/partners.types';
import {Children} from 'types/base.types';

/* Styles */
import styles from './logosdisplay.module.scss';

type LogosDisplayProps = {
  /* Partner */
  partner?: PartnerBaseAttributes;
  /* Rather than have to include the stylesheet everywhere. Centralize the style, and pass in the class name */
  classType?: string;
};

const LogosDisplay = ({partner, classType = ''}: LogosDisplayProps = {}) => {
  /* Selectors */
  const hasPartnerPlanSubscription = useUserSubscriptionPartnerSelector();
  const queryPartner = (usePartnerCache() as PartnerBaseAttributes) || partner;

  /* Hooks */
  const {logo, altText} = useGetPartnerImage(queryPartner);
  /* Style update */
  const logoContainerStyles = cn(styles.logoContainer, {
    [styles[classType]]: classType in styles,
  });

  /**
   * Do we want the logo to have a link?
   *  1. Is it a non-partner? Link it (to the homepage)
   *  2. Is it a partner? Link it (to the partner homepage) if it has a homeURL
   *
   * @param children
   * @returns {React.JSX.Element}
   * @constructor
   */
  const ConditionalLink = ({children}: {children: Children}): React.JSX.Element => {
    const toHTHome = !queryPartner?.id || hasPartnerPlanSubscription;
    const toPartnerHome = queryPartner?.homeUrl;
    const to = toHTHome ? '/' : toPartnerHome;

    if (to) {
      return (
        <Link href={to} className="cursorPointer">
          {children}
        </Link>
      );
    }

    return <>{children}</>;
  };

  /*
   * Cobrand "could" have left partners logo. Walmart is an example
   */
  const htLogo = (
    <ConditionalLink>
      <div style={{minWidth: '125px'}}>
        <HTLogo />
      </div>
    </ConditionalLink>
  );
  const partnerLogo = logo ? (
    <div className={styles.logo}>
      <PartnerLogo src={logo as string} altText={`${altText}`} />
    </div>
  ) : null;

  const [LogoLeft, LogoRight] = [htLogo, partnerLogo][(queryPartner as PartnerClient)?.logoPosition === 'left' ? 'reverse' : 'slice']();

  return (
    <div className={cn(logoContainerStyles, 'cursorPointer')}>
      {LogoLeft}
      {LogoLeft && LogoRight && <div className={styles.verticalLine} />}
      {LogoRight}
    </div>
  );
};

export default memo(LogosDisplay);
