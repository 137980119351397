import React from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import styles from './htlogo.module.scss';

type HTLogoProps = {
  className?: string;
  defaultCursor?: boolean;
};

/**
 * PartnerLogo
 *
 * Only to be used in headers.
 */
const HTLogo = ({className = '', defaultCursor = false}: HTLogoProps) => {
  const iconStyles = cn(
    styles.htLogo,
    {
      [styles.defaultCursor]: defaultCursor,
      topNavHelloTechLogo: true,
    },
    className,
  );

  return <Icon name="logo" className={iconStyles} />;
};

export default HTLogo;
