/* eslint-disable consistent-return */
import {useEffect} from 'react';
/* Queries */
import usePartnerCache from '@integrations/partners/usePartnerQuery.cache';

/* Hooks */
import {useUserHasSubscriptionSelector} from '@store/queries/user/useUser.selector';

/* Constants */
import {HT_INFO_PHONE} from '@constants/hellotech';

/* Types */
import { useTrackingActions, useTrackingPhone } from '@store/client/store.tracking';
import {TPhone} from 'types/base.types';
import useBrandingCache from '@integrations/partners/branding/useBrandingQuery.cache';

type PhoneProps = {
  link: string;
  title: string;
  value: string;
};

/**
 * ---------------------------------------------------
 * How we can get at a phone number
 *
 * @param {PhoneProps} seed
 * @returns {PhoneProps}
 * ---------------------------------------------------
 */
function usePhone(seed?: PhoneProps) {
  /* Client State */
  const phone = useTrackingPhone();
  const {setPhoneState} = useTrackingActions();

  /* Hooks */
  const hasSubscription = useUserHasSubscriptionSelector();

  /* Do we have a partner phone (in the partner object) */
  const cachedPartner = usePartnerCache();
  const cachedBranding = useBrandingCache();

  /* Constants */
  const basePhone = HT_INFO_PHONE.customers.default;
  const subscriberPhone = HT_INFO_PHONE.customers.subscriptionHolder.default;
  /* Methods */
  const getPhoneValue = (phoneValue: string | number | TPhone) => {
    if (!phoneValue) {
      return false;
    }

    if (typeof phoneValue === 'string' || typeof phoneValue === 'number') {
      return true;
    }

    if (typeof phoneValue === 'object') {
      if (phoneValue.value) return true;
      return false;
    }

    return false;
  };

  useEffect(() => {
    /**
     * ---------------------------------------------------
     * planAssociatedPhoneNumber - if we have a plan, use it
     * subscriberPhone - if we have a subscription, use it
     * cachedPartner - if we have a partner, use it
     * phone - if we have a phone, usually from a query (adword phone etc)
     * basePhone - if we have nothing, use the default
     */
    const phoneToUse = [
      seed,
      cachedPartner?.planAssociatedPhoneNumber,
      cachedBranding?.planAssociatedPhoneNumber,
      hasSubscription && subscriberPhone,
      cachedPartner?.phone,
      phone,
      basePhone,
    ].find(getPhoneValue);

    setPhoneState(phoneToUse);
  }, [hasSubscription, subscriberPhone, cachedPartner]);

  /* Subscription is not recognized */
  if (!phone?.title) {
    return basePhone;
  }

  return phone;
}

export default usePhone;
