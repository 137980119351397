import React from 'react';

type PartnerLogoProps = Partial<{
  className: string;
  defaultCursor: boolean;
  altText: string;
  src: string;
}>;

/**
 * PartnerLogo
 *
 * Only to be used in headers.
 */
const PartnerLogo = ({className = '', defaultCursor = false, src = '', altText = ''}: PartnerLogoProps) => {
  if (!src) return null;

  return <img alt={`${altText} products and services`} src={src} />;
};

export default PartnerLogo;
