import React from 'react';
import {Icon} from 'ht-styleguide';

/* Integrations */
import {SegmentTrackingAction} from '@integrations/segment/banner';

/* Types */
type PromoHeadlineProps = {
    ctaText: string;
    headline: string;
    icon: string;
    ctaLink: string;
    ctaLinkExternal?: string;
    ctaLinkTarget?: string;
};

const DisplayHeadlineAndLink = (props: PromoHeadlineProps) => {
  const {ctaText, headline, ctaLink, icon, ctaLinkExternal = ''} = props || {};
  const DisplayIcon = () => {
    if (icon) return <Icon name={icon} />;
    return null;
  };
  return (
    <>
      <DisplayIcon />
      {/* TODO: update with next/link when integrated */}
      <a
        className="p1"
        href={ctaLink || ctaLinkExternal}
        onClick={() => {
          SegmentTrackingAction.promoBanner(headline || 'no banner headline');
        }}
      >
        {headline}&nbsp;{ctaText}
      </a>
    </>
  );
};

export default React.memo(DisplayHeadlineAndLink);
