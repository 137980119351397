/* Libs */
import Segment from '../index';
/* Types */

/**
 * -------------------------------------------------------------------------------------
 * Define the "event name" here, in TRACKING_TYPES
 */
export const TRACKING_TYPES = {
  promoBanner: 'Promotion Clicked',
};

/**
 * -------------------------------------------------------------------------------------
 * These are just "actions" that are consolidated in one place that
 * will return your properties structure. Just "method" out what you
 * need to handle.
 */
export const SegmentTrackingAction = {
  /* banner components */
  promoBanner(headline: string) {
    const payload = {
      promo_message: headline,
    };

    Segment.track(TRACKING_TYPES.promoBanner, payload);
  },
};
