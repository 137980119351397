import {Modal, Button, BUTTON_THEMES} from 'ht-styleguide';

/* App Store: snack */
import useNotificationStore, {Notification, deleteAppNotification} from '@store/client/store.notifications';

type ConfirmButtonProps = {
  close: BaseAnyFunction;
};

const ConfirmButton = ({close}: ConfirmButtonProps) => (
  <Button small theme={BUTTON_THEMES.V2PRIMARY} onClick={close}>
    Got it
  </Button>
);

const NoticeStack = () => {
  const notifications = useNotificationStore(state => state.notifications);

  if (!Array.isArray(notifications)) return null;

  const close = () => deleteAppNotification();

  return (
    <>
      {notifications.map((notice: Notification, idx: number) => (
        <Modal key={idx} isVisible header={notice.title || ''} hide={close} footerElement3={<ConfirmButton close={close} />}>
          <p className="p1 n700" dangerouslySetInnerHTML={{__html: notice.content!}} />
        </Modal>
      ))}
    </>
  );
};

export default NoticeStack;
