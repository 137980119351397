import useCartCache from '@store/queries/cart/useCart.cache';
import {useCartCountSelector} from '@store/queries/cart/useCart.selector';
import {formatDollarAmount} from '@utils/currency';
import {Cart} from 'types/cart.types';

export const GetCartItemCount = () => {
  const count = useCartCountSelector();

  if (!count) return 0;

  return count;
};

export const IsPlanOnlyCart = () => {
  const cart = useCartCache();

  if (!cart) return false;
  return !!cart.plan && cart.items.length === 0;
};

export const getTotalCreditAdjustments = (cart: Cart | null = null) => {
  if (cart?.breakdown) {
    const {adjustments, subscriptionAdjustments} = cart.breakdown;
    const creditGlobalAdjustments = adjustments.filter(adj => adj.type === ('credit' as string));
    const subscriptionCreditAdjustments = subscriptionAdjustments.filter((adj: {type: string}) => adj.type === 'credit');
    const totalCredit = [...creditGlobalAdjustments, ...subscriptionCreditAdjustments].reduce((acc, adj) => acc + adj.amount, 0);

    return {
      amount: totalCredit,
      amountFormatted: formatDollarAmount({amount: totalCredit, truncateEvenDollars: true}),
      amountFormattedAbs: formatDollarAmount({
        amount: Math.abs(totalCredit),
        truncateEvenDollars: true,
      }),
    };
  }
  return {
    amount: 0,
    amountFormatted: '$0',
    amountFormattedAbs: '$0',
  };
};
