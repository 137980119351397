import { skuIsPriceTypeHourly } from '@utils/sku';
import { TPriceType } from 'types/sku.types';

// E.g. formatter(0).format(49.00)
export const formatter = (digits = 0) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  });

export const formatPrice = (price: number, cents = true) => `$${price / ((cents && 100) || 1)}`;

/*
Example Usage - Hide "00" for even dollar amounts ($72 not $72.00), but show it for
amounts with cents ($72.99)
---
import { formatDollarAmount } from 'src/utils/formatter/currency';
const evenDollars = 7200;
const unevenDollars = 7299;
formatDollarAmount({amount: evenDollars, truncateEvenDollars: true})    -> Returns $72
formatDollarAmount({amount: evenDollars})                               -> Returns $72.00
formatDollarAmount({amount: unevenDollars})                             -> Returns $72.99
formatDollarAmount({amount: unevenDollars, truncateEvenDollars: true})  -> Returns $72.99
*/
const isEvenDollarAmount = (num: number) => String(num).slice(-2) === '00';
const getFormatterDigit = (num: number) => (isEvenDollarAmount(num) ? 0 : 2);
// Provide the amount in cents, e.g. 7200 represents $72
export const formatDollarAmount = ({amount, digits = 2, truncateEvenDollars = false}: {amount: number; digits?: number; truncateEvenDollars: boolean}) => {
  if (!amount) return null;
  if (truncateEvenDollars) return formatter(getFormatterDigit(amount)).format(amount / 100);

  return formatter(digits).format(amount / 100);
};

/**
 * Formats the price for hourly services.
 *
 * @param {Object} params - The parameters.
 * @param {Object} params.sku - The service or the priceType
 * @param {string} params.price - The formatted price.
 * @param {boolean} params.withAsterik - Whether to append an asterisk to the price.
 * @returns {string} The formatted price.
 */


export const formatPriceTypeHourly = ({ sku, price, withAsterik = false }: {sku: TPriceType, price: string, withAsterik?: boolean;}): string => {
  // Check if the service is hourly
  if (!skuIsPriceTypeHourly(sku) || !price) {
    return price;
  }

  const asterik = withAsterik ? '*' : '';

  return `${price}/hr${asterik}`;
};
