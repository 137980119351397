import { useLayoutEffect as ule, useEffect } from 'react';
import { isBrowser } from '@utils/environment';

const useLayoutEffect = isBrowser() ? ule : useEffect;

export default useLayoutEffect;

/*
* This alleviates console/logged errors during build time as useLayoutEffect is not
* available in SSR
* */
