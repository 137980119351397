/* Libraries */
import React from 'react';
import dynamic from 'next/dynamic';

/* Components */
import BaseLayout from '@components/PageLayouts/BaseLayoutContent';

/* Hook */
import useGetPartner from '@integrations/partners/useGetPartner';

/* Types */
import {Children} from 'types/base.types';
import {PageConfigProps} from 'types/page.types';
import {PageLayoutAttributesBase} from '@components/PageLayouts/pagelayouts.constants';

type StandardLayoutProps = {
  children: Children;
  pageConfig: PageConfigProps['pageConfig'];
};

/* Dynamic components */
const StandardFooterDynamic = dynamic(() => import('@components/PageLayouts/Footer/Standard'), {ssr: false});
const StandardHeaderDynamic = dynamic(() => import('@components/PageLayouts/Header/Standard'), {ssr: false});
/**
 * This is the seed/base layout determinant when a page can have multi layouts. Ie. add-sku.
 * We can derive the "partners" key via a utils function.
 *
 * Is Partner
 *   Yes:
 *      - Bring in Partner Layout
 *  No:
 *      - Bring in standard layout
 *
 * NOTE: This is a very fast/half-assed approach.
 *       Why? Because how we deal with partners/templates is changing (design wise).
 *       As such, the solution is literally up-in-the-air.
 *       Also: Next.js is changing their layouts. So, branding will become much more robust.
 *       So, we need to leverage the NEW layouts routing & how design/partnerships scope out.
 *
 *       Note: There are two ways to determine partners at ssg/ssr time, and cookie.
 *             This here is not on client level. We'll do that in the header/footer proper.
 */
const getLayout =
  (config: StandardLayoutProps['pageConfig']) =>
  (page: $FixMe = {}) => {
    const {partner, PartnerHeader} = useGetPartner(page.props?.partner);
    /* Merge passed config with base defaults */
    const mergedConfig = {...PageLayoutAttributesBase, ...config};

    /* If our page is not parnterEnabled, clear partner */
    /* Constants */
    const layoutFooter = () => <StandardFooterDynamic partner={partner} />;
    const layoutHeader = !PartnerHeader ? StandardHeaderDynamic : () => <PartnerHeader partner={partner} pageConfig={mergedConfig} />;

    return (
      <BaseLayout partner={partner} Header={layoutHeader} Footer={layoutFooter} pageConfig={mergedConfig}>
        {page}
      </BaseLayout>
    );
  };

export default getLayout;
