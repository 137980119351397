import {State, StoreApi} from 'zustand';

/* Simple abstraction to assist in setting simple values */
export const simpleStoreSetter =
  (store: StoreApi<State>, key: string, seededValue?: any) =>
  (value: any = seededValue) => {
    store.setState((state: any) => {
      state[key] = value;
    });
  };
