import React, {useRef, useState, useEffect, useCallback} from 'react';
import useLayoutEffect from '@components/IsomorphicLayoutEffect';
import cn from 'classnames';
import debounce from 'lodash/debounce';

// Components
import HeadlineLnk from 'src/components/PromoBar/HeadlineLink';

/* Client State */
import {useResponsiveIsMobileOrTablet} from '@store/client/store.responsive';
import {useMobileSidebarOpenMobile} from '@store/client/store.mobile';
import {useAppSearchBarIsOpen} from '@store/client/store.app';

/* Constants */
import {SPLITIONAME_PROMO_BAR} from '@integrations/split/split.constants';

/* Hooks & Utils */
import useEventListener from '@hooks/useEventListener';
import useSplit from '@integrations/split/useSplit';

// Styles
import styles from 'src/components/PromoBar/promobar.module.scss';

/**
 * Promo/Notification banner (GROW-132). A little involved due to the layout of the site and mobile vs. desktop.
 * User can dismiss for 2 days, see cookie code.
 *
 * @param promo
 * @returns {null|*}
 * @constructor
 */
const PromoBanner = (promoProps: any) => {
  const {refHeader, refParent} = promoProps;

  /* Client State */
  const isMobileOrTablet = useResponsiveIsMobileOrTablet();
  const sidebarOpenMobile = useMobileSidebarOpenMobile();
  const searchBarIsOpen = useAppSearchBarIsOpen();

  /* Local State */
  const [showPromoBar, setShowPromoBar] = useState(false);

  const promoBannerContainer = useRef(null);
  const promoBannerHeight = useRef(48);
  const [parentHeader, setParentHeader] = useState(null);
  const [hideBannerOnMobile, setHideBannerOnMobile] = useState(false);

  // Split integration
  const {treatmentConfig} = useSplit(SPLITIONAME_PROMO_BAR);
  const {textColor, backgroundColor} = treatmentConfig;

  // Helper functions
  const setParentHeaderHeight = (value: number | boolean) => {
    if (parentHeader) {
      (parentHeader as HTMLDivElement).style.top = `-${Number.isInteger(value) ? value : promoBannerHeight.current}px`;
    }
  };

  const setHeightValues = () => {
    if (parentHeader && promoBannerContainer.current) {
      const {height} = (promoBannerContainer.current as HTMLDivElement).getBoundingClientRect();

      promoBannerHeight.current = height;
      setParentHeaderHeight(height);
    }
  };

  /**
   Since the main header height changes at breakpoints, we need to update a few values.
   */
  const handleResize = useCallback(
    debounce(() => {
      setHeightValues();
    }, 300),
    [parentHeader],
  );

  useEventListener({eventName: 'resize', handler: handleResize});

  /**
   Let's save the parent ref (MainHeader) in a state. This will ensure us that the ref exists
   and that the ref for the promo also exists.
   */
  useEffect(() => {
    if (!parentHeader) {
      setParentHeader(refHeader.current);
    }
  }, [refHeader]);

  /**
   Once the parentHeader ref is saved, let's save the height of the promo banner
   and set the top position of the parent header. We're setting the top position
   because if the user scrolls, the offset will allow the parent header to stay
   flush to the top of the browser. Also, note that the parent header is positioned
   sticky.
   */
  useLayoutEffect(() => {
    handleResize();
  }, [parentHeader, showPromoBar]);

  /**
   For Tablet/Mobile only - if the sidebar or search bar is open, hide the promo and
   set the parent header's top position to 0.
   */
  useLayoutEffect(() => {
    if (isMobileOrTablet) {
      if (sidebarOpenMobile || searchBarIsOpen) {
        setHideBannerOnMobile(true);
        refParent.current.style.height = 0;
        setParentHeaderHeight(0);
      } else {
        if (refParent.current) refParent.current.style.height = '48px';

        setHideBannerOnMobile(false);
        setParentHeaderHeight(false);
      }
    }
  }, [sidebarOpenMobile, searchBarIsOpen]);

  /**
   Reset the parent header's top position when there is a route change. This ensures
   that the header is in the correct position if the new route doesn't display the promo.
   */
  useEffect(
    () => () => {
      if (parentHeader) {
        // @ts-ignore
        parentHeader.style.top = 0;
      }
    },
    [parentHeader],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPromoBar(true);
      handleResize();
    }, 500);

    return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before the timeout finishes
  }, []);

  const promotionalContainerStyles = cn(styles.promotionalContainer, {
    [styles.hide]: hideBannerOnMobile,
  });

  if (!parentHeader || !showPromoBar) return null;

  return (
    <div
      ref={promoBannerContainer}
      className={promotionalContainerStyles}
      style={{
        color: textColor,
        backgroundColor,
      }}
    >
      <div className={styles.contentLeft} />
      <p className={`p1-hero ${styles.contentCenter}`}>
        <HeadlineLnk {...treatmentConfig} />
      </p>
      <div className={styles.contentRight} />
    </div>
  );
};

const PromoBar = PromoBanner;

export default PromoBar;
