import {formatPrice} from '@utils/currency';
import {Sku, TPriceType} from 'types/sku.types';
import {Cart, SubscriptionPrices} from 'types/cart.types';
import {BREAKDOWN_PRICE_TYPES} from '@constants/cart';

/**
 * -----------------------------------------------------------------------
 * SKU SPECIFIC UTILITIES. Either ServiceSku or ProductSku.
 * -----------------------------------------------------------------------
 */

/* --------------------------------------------------------- */
// skuPrices
/* --------------------------------------------------------- */
export const skuPrices = (sku: Sku, cart: Cart, subscriptionPrices: SubscriptionPrices) => {
  const price = cart.remote || !cart.items?.length || !sku.remoteSavings ? sku.startsAtPrice : formatPrice(sku.basePrice + sku.remoteSavings);
  const memberPrice = subscriptionPrices && subscriptionPrices.cheapestPrice != null ? formatPrice(subscriptionPrices.cheapestPrice) : null;
  const rawPrice = price?.replace(/[^0-9.]/g, '');
  const rawMemberPrice = memberPrice != null ? memberPrice.replace(/[^0-9.]/g, '') : null;
  const rawPriceDifference = (rawMemberPrice && rawPrice && +rawPrice - +rawMemberPrice) || 0;
  return {
    price,
    memberPrice,
    priceDifference: (rawMemberPrice != null && rawPriceDifference > 0 && formatPrice(rawPriceDifference, false)) || null,
  };
};

/* Pass in root object (where priceType sits) or priceType */
export const skuIsPriceTypeHourly = (sku: TPriceType | string | number) => {
  let priceType;
  if (typeof sku === 'object' && 'priceType' in sku) {
    priceType = sku.priceType;
  } else {
    priceType = sku;
  }

  return sku && priceType === BREAKDOWN_PRICE_TYPES.hourly;
};
