// @ts-nocheck
import { useState, useCallback } from 'react';
import { prevented } from '@utils/event';

const useToggle = (initialValue = false) => {
  const [toggleState, setToggleState] = useState(initialValue);
  const toggle = useCallback(prevented(() => setToggleState(!toggleState), [toggleState]));
  const setBooleanToggle = useCallback((bool) => setToggleState(bool), []);

  return {
    toggleState,
    setToggleState,
    toggle,
    setBooleanToggle,
  };
};

export default useToggle;
